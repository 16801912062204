import React, { useEffect, useState } from 'react';
import { Card, Box, Button, Stack, Typography, FormControl, InputLabel, Select, MenuItem, Link, OutlinedInput, useTheme } from "@mui/material";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import Page from "src/components/Page";
import { useAccess } from 'src/hooks/useAccess.hook';
import { FEATURE } from 'src/util/enums';
import axios from '../../util/axios';
import { reportListColumn } from './ReportTabList.constant';
import { ReactComponent as TotalEnergy } from "../../image/total_energy.svg"
import { ReactComponent as TotalRevenue } from "../../image/total_revenue.svg"
import { ReactComponent as TotalSession } from "../../image/total_session.svg"
import { styled } from '@mui/material/styles';
import { CommonSearchbar } from 'src/layouts/dashboard/Searchbar';
import { yearFirstDateTimeFormat } from 'src/utils/formatTime';
import { format } from 'date-fns';
import { useSelectedTeam } from 'src/hooks/useSelectedTeam';
import { reportTabData } from 'src/react-query/endPoints';
import * as XLSX from "xlsx";
import { RootTabItemStyles } from 'src/theme/overrides/Tabs';
import { TabContext, TabList } from "@mui/lab";
import { downloadCSV } from 'src/utils/convertAnddownloadCsv';
import { useNavigate } from "react-router-dom";

const BoxStyle = styled('div')(({ theme }) => ({
    width: "100%",
    border: "1px solid #00000036",
    display: "grid",
    gridTemplateRows: "20% 80%",
    placeItems: "center",
    padding: "1rem",
    borderRadius: "10px"
}));

const RootTabStyle = styled(TabList)(({ theme, length }) => ({
    width: '98%',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    '& .MuiTabs-flexContainer': {
        justifyContent: 'space-between',
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const ReportTabList = () => {
    const navigate = useNavigate();
    const [reportTableData, setReportTableData] = useState();
    const allTimeMenu = ["Today", "Yesterday", "Last 7 days", "Last 30 days", "Last 90 days"];
    const [timeInterval, setTimeInterval] = useState();
    const [tableData, setTableData] = useState();
    const [totalData, setTotalData] = useState({ totalEnergy: "", totalRevenue: "", totalSession: "" })
    const [getDate, setGetDate] = useState({ startDate: "", endDate: "" })
    const [uniqueChargeBoxIdValues, setUniqueChargeBoxIdValues] = useState();
    const [uniquePayoutStatus, setUniquePayoutStatus] = useState();
    const isReadOnly = useAccess(FEATURE.REPORT, "RP");
    const finalTableColumns = reportListColumn(isReadOnly);
    const { teamId } = useSelectedTeam();
    const [selectedTab, setSelectedTab] = useState("Statement Report Tab");
    const tabsToShow = [{ value: "Statement Report Tab", label: "Statement Report Tab" }]
    const [chargeBoxName, setChargeBoxName] = useState([]);
    const [payoutStatus, setPayoutStatus] = useState([]);

    const rowOrCellClickRedirect = ({ id }) => {
        // if(isReadOnly) return;
        navigate(`/transactions/${id}`);
      };

    useEffect(() => handleChangeTimeInterval("Today"), []);

    useEffect(() => {
        if (getDate.startDate && getDate.endDate && teamId) {
            const fetchData = () => {
                return new Promise((resolve, reject) => {
                    axios.get(reportTabData(teamId, getDate.startDate, getDate.endDate))
                        .then(response => {
                            resolve(response.data);
                        })
                        .catch(error => {
                            reject(error);

                        });
                });
            };

            fetchData()
                .then(data => {
                    let chargeBoxValue = [...new Set(data.data.map(item => item.charge_box_id))];
                    let payoutStatus = [...new Set(data.data.map(item => item.status))];
                    setUniqueChargeBoxIdValues(chargeBoxValue);
                    setUniquePayoutStatus(payoutStatus);
                    getHeaderData(data.data);
                    setReportTableData(data.data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [getDate.startDate, getDate.endDate, teamId])

    const getHeaderData = (reportList) =>{
        let energySum = 0;
        let revenueSum = 0;
        reportList.map((sum) => {
            energySum += sum.totalEnergy;
            revenueSum += Number(sum?.gst !== "" ? sum.payout_with_gst : sum.payout_without_gst);
        })
        setTotalData({ totalEnergy: parseFloat(energySum).toFixed(2), totalRevenue: parseFloat(revenueSum).toFixed(2), totalSession: reportList.length });
        setTableData(reportList);
    }

    const handleChangeTimeInterval = (value) => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        let startDate = format(date, "yyyy-MM-dd HH:mm:ss")
        setTimeInterval(value)
        switch (value) {
            case "Today":
                {
                    let endDate = new Date();
                    setGetDate({ startDate: startDate, endDate: format(endDate, "yyyy-MM-dd HH:mm:ss") })
                }
                break;
            case "Yesterday":
                {
                    let ystrDate = new Date().setDate(new Date().getDate() - 1)
                    let actualDate = format(new Date(ystrDate).setHours(0, 0, 0, 0), "yyyy-MM-dd HH:mm:ss")
                    setGetDate({ startDate: actualDate, endDate: format(new Date(actualDate).setHours(23, 59, 59), "yyyy-MM-dd HH:mm:ss") })
                }
                break;
            case "Last 7 days":
                {
                    let endDate = new Date().setDate(new Date().getDate() - 7)
                    setGetDate({ startDate: format(new Date(endDate).setHours(0, 0, 0, 0), "yyyy-MM-dd HH:mm:ss"), endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss") })
                }
                break;
            case "Last 30 days":
                {
                    let endDate = new Date().setDate(new Date().getDate() - 30)
                    setGetDate({ startDate: format(new Date(endDate).setHours(0, 0, 0, 0), "yyyy-MM-dd HH:mm:ss"), endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss") })
                }
                break;
            case "Last 90 days":
                {
                    let endDate = new Date().setDate(new Date().getDate() - 90)
                    setGetDate({ startDate: format(new Date(endDate).setHours(0, 0, 0, 0), "yyyy-MM-dd HH:mm:ss"), endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss") })
                }
                break;
            default:
                break;
        }
    }

    const handleSearching = (event) => {
        const dataToSearchFrom = reportTableData;
        const searchValue = event.target.value.trim().toString().toLowerCase();
        if (searchValue) {
            const filteredDataArr = dataToSearchFrom.reduce(
                (filteredDataArr, currentObj) => {
                    const relevantFields = [
                        "transaction_pk",
                        "charge_box_id",
                        "connector_id",
                        "start_value",
                        "stop_value",
                        "totalEnergy",
                        "payment_type",
                        "amount_paid",
                        "charging_cost",
                        "cm_commission",
                        "payout_with_gst",
                        "payout_without_gst",
                        "status",
                    ];
                    const allValuesArr = Object.entries(currentObj)
                        .filter(([key, value]) => relevantFields.includes(key))
                        .map(([key, value]) =>
                            key === "starttime" || key === "stoptime"
                                ? yearFirstDateTimeFormat(value).toString()
                                : value ? value.toString().toLowerCase() : ""
                        );
                    const searchResult = allValuesArr.find((value) =>
                        value.includes(searchValue)
                    );
                    if (searchResult) {
                        filteredDataArr = [...filteredDataArr, currentObj];
                    }
                    return filteredDataArr;
                },
                []
            );
            setTableData(filteredDataArr);
        } else setTableData([...reportTableData]);
    };

    const saveToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(reportTableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, 'report.xlsx');
    };

    const handleTabChange = (e, tab) => {
        setSelectedTab(tab);
    }

    function convertArrayOfObjectsToCSV(arrayOfObjects) {
        if (!arrayOfObjects || !Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
            return '';
        }
        const rows = arrayOfObjects.map((object) => {
            const values = Object.values(object).map(value => `"${value}"`);
            return values.join('; ');
        });
        return rows.join('\n');
    }


    const handleExport = () => {
        const csv = convertArrayOfObjectsToCSV(reportTableData);
        downloadCSV(csv, "Transaction");
    }

    const handleChangeChargeBox = (event) => {
        const {
            target: { value },
        } = event;
        setChargeBoxName(
            typeof value === 'string' ? value.split(',') : value,
        );
        let filteredData = value.length > 0 || payoutStatus.length > 0  ? reportTableData.flat().filter(item => payoutStatus.length > 0 ? value.length > 0 ? value.includes(item.charge_box_id) && payoutStatus.includes(item.status) : payoutStatus.includes(item.status) : value.includes(item.charge_box_id)) : reportTableData;
        getHeaderData(filteredData);
    };

    const handleChangePayoutStatus = (event) => {
        const {
            target: { value },
        } = event;
        setPayoutStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
        let filteredData = value.length > 0 || chargeBoxName.length > 0 ? reportTableData.flat().filter(item => chargeBoxName.length > 0 ? value.length > 0 ? value.includes(item.status) && chargeBoxName.includes(item.charge_box_id) : chargeBoxName.includes(item.charge_box_id)  : value.includes(item.status)) : reportTableData;
        getHeaderData(filteredData);
    };

    return (
        <Page title="Report | Smart-CMS">
            <TabContext value={selectedTab}>
                <Stack direction="row" justifyContent="center" alignItem="center" sx={{ width: "100%" }}>
                    <RootTabStyle
                        indicatorColor="transparent"
                        centered
                        length={tabsToShow.length}
                        onChange={handleTabChange}
                    >
                        {
                            tabsToShow.map((tab) => <RootTabItemStyles value={tab.value} label={tab.label} />)
                        }
                    </RootTabStyle>
                </Stack >
                <Card sx={{ p: 4 }}>
                    <Stack direction="flex" sx={{ gap: "4rem" }}>
                        <BoxStyle>
                            <Typography mt={2} mb={1} sx={{ fontWeight: "600", fontSize: "16px" }}>Total Energy</Typography>
                            <Box sx={{ display: "flex", placeItems: 'center', gap: '2rem' }}>
                                <TotalEnergy />
                                <Box sx={{ color: "#00AB55" }}>
                                    <Typography sx={{ display: "inline-block", fontWeight: "600", fontSize: "2rem" }}>{totalData.totalEnergy}</Typography>
                                    <Typography sx={{ display: "inline-block" }}>KwH</Typography>
                                </Box>
                            </Box>
                        </BoxStyle>
                        <BoxStyle>
                            <Box sx={{ display: "flex", flexDirection: "column", placeItems: "center" }}>
                                <Typography mb={1} mt={2} sx={{ fontWeight: "600", fontSize: "16px" }}>Total Revenue</Typography>
                                <Typography mb={1} sx={{ marginTop: "-10px", fontSize: "12px" }}>(CPO Payout)</Typography>
                            </Box>
                            <Box sx={{ display: "flex", placeItems: 'center', gap: '2rem' }}>
                                <TotalRevenue />
                                <Typography sx={{ color: "#00AB55", fontWeight: "600", fontSize: "2rem" }}>{totalData.totalRevenue}</Typography>
                            </Box>
                        </BoxStyle>
                        <BoxStyle>
                            <Typography mb={1} mt={2} sx={{ fontWeight: "600", fontSize: "16px" }}>Total Session</Typography>
                            <Box sx={{ display: "flex", placeItems: 'center', gap: '2rem' }}>
                                <TotalSession />
                                <Typography sx={{ color: "#00AB55", fontWeight: "600", fontSize: "2rem" }}>{totalData.totalSession}</Typography>
                            </Box>
                        </BoxStyle>
                        <Box sx={{ width: "100%" }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">All Time</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // value={timeInterval}
                                    label="All Time"
                                    defaultValue={timeInterval}
                                    onChange={(event) => handleChangeTimeInterval(event.target.value)}
                                >
                                    {allTimeMenu.map(data => <MenuItem key={data} value={data}>{data}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>

                    </Stack>
                    <Box>
                        <Typography mt={4} sx={{ borderBottom: "2px solid #00000036", paddingBottom: "12px" }}>STATEMENT REPORT</Typography>
                        <Stack direction="flex" mt={2} mb={4} sx={{ gap: "1rem", alignItems: "center" }}>
                            <Box sx={{ position: "relative" }}>
                                {/* {reportTableData && reportTableData.length > 0 && */}
                                <Button variant="contained" onClick={saveToExcel}>
                                    Export To Excel
                                </Button>
                            </Box>
                            <Box>
                                <Button variant="contained" onClick={handleExport}>Export to CSV</Button>
                            </Box>
                            {/* <Box>
                            <Button variant="contained"><Link sx={{color: '#fff', textDecoration: 'none'}} href ="mailto:info@chargemiles.com" target="_blank" rel="noreferrer">Send to Mail</Link></Button>
                        </Box> */}
                           {tableData?.length > 0 && <div>
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="demo-multiple-name-label">Charge Box Id</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        multiple
                                        value={chargeBoxName}
                                        onChange={handleChangeChargeBox}
                                        input={<OutlinedInput label="Charge Box Id" />}
                                        MenuProps={MenuProps}
                                    >
                                        {uniqueChargeBoxIdValues?.length > 0 && uniqueChargeBoxIdValues.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            //   style={getStyles(name,chargeBoxName, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="demo-multiple-name-label">Payout Status</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        multiple
                                        value={payoutStatus}
                                        onChange={handleChangePayoutStatus}
                                        input={<OutlinedInput label="Charge Box Id" />}
                                        MenuProps={MenuProps}
                                    >
                                        {uniquePayoutStatus?.length > 0 && uniquePayoutStatus.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            //   style={getStyles(name,chargeBoxName, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>}
                        </Stack>
                        {!tableData?.length > 0 && <Stack>
                            <Typography sx={{color: "red"}}>This team have no transaction for {timeInterval} session. So no statement generated.</Typography>
                        </Stack>}
                        <CommonSearchbar
                            placeholder="Search"
                            searchFunc={(e) => handleSearching(e)}
                        />
                        <BaseTable
                            isReadOnly={isReadOnly}
                            rows={tableData ?? []}
                            columns={finalTableColumns}
                            loading={false}
                            getRowId={(row) => row.transaction_pk}
                            pageSize={10}
                            showExportCsvBtn={false}
                            rowOrCellClickRedirect={rowOrCellClickRedirect}
                            sx={{
                                ".MuiDataGrid-columnHeaderTitle": {
                                    overflow: "hidden   !important",
                                    lineHeight: "20px   !important",
                                    whiteSpace: "normal  !important",
                                },
                            }}
                        />
                    </Box>
                </Card>
            </TabContext>
        </Page>
    )
}

export default ReportTabList

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import Page from "src/components/Page";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { useTransactions } from "../transactions.hook";
import { transactionsListColumns } from "./TransactionsList.constants";
import { useNavigate } from "react-router-dom";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { useState, useEffect } from "react";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";

export const TransactionsList = () => {
  const navigate = useNavigate();
  const { teamId } = useSelectedTeam();
  const isReadOnly = useAccess(FEATURE.TRANSACTION, "R");

  const { data, isFetching } = useTransactions({ teamId });
  const initialColumns = transactionsListColumns(isReadOnly);
  const finalTableColumns = initialColumns.filter(({ field }) => field !== "status" && field !== "notes")
  const [tableData, setTableData] = useState();
  const rowOrCellClickRedirect = ({ id }) => {
    // if(isReadOnly) return;
    navigate(`/transactions/${id}`);
  };

  useEffect(() => {
    if (!isFetching) {
      let arr = [...data];
      setTableData(arr);
    }
  }, [isFetching]);

  const handleSearching = (event) => {
    const dataToSearchFrom = data;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "transactionId",
            "email",
            "phone",
            "chargepoint_id",
            "connectorId",
            "payment_type",
            "totalAmountPaid",
            "totalAmountCharged",
            "totalRefund",
            "starttime",
            "stoptime",
            "status",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "starttime" || key === "stoptime"
                ? yearFirstDateTimeFormat(value).toString()
                : value ? value.toString().toLowerCase() : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      // console.log(filteredDataArr);
      setTableData(filteredDataArr);
    } else setTableData([...data]);
  };

  return (
    <Page title="Transactions | Smart-CMS">
      <Card sx={{ py: 2, px: 1, width: "103%" }}>
        <CommonSearchbar
          placeholder="Search"
          searchFunc={(e) => handleSearching(e)}
        />
        <BaseTable
          isReadOnly={isReadOnly}
          rows={tableData ?? []}
          columns={finalTableColumns}
          loading={isFetching}
          getRowId={(row) => row.transactionId}
          pageSize={10}
          showExportCsvBtn={true}
          csvMorphemes="transactions"
          csvColumns={initialColumns}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              overflow: "hidden   !important",
              lineHeight: "20px   !important",
              whiteSpace: "normal  !important",
            },
          }}
        />
      </Card>
    </Page>
  );
};

import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { authService } from "src/services";
import Token from "../../../util/Token";
import enums from "src/util/enums";
import { authSlice } from "src/store/auth/auth.slice";
import { useDispatch } from "react-redux";
import { alertSlice } from "src/store/alert/alert.slice";
import { useMutation } from "@tanstack/react-query";
import { loginFormValidationSchema } from "src/util/validationSchemas";

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { mutate, isLoading, reset } = useMutation(authService.login);
  const navigate = useNavigate();

  const onLoginSuccess = (response) => {
    const loginResponse = response?.data?.data;
    if (loginResponse?.access_token) {
      Token.set(loginResponse.access_token);
      dispatch(authSlice.actions.setAuth(response?.data?.data?.user));
      navigate(enums.path.DASHBOARD_APP);
    } else {
      dispatch(
        alertSlice.actions.setAlert({
          severity: "error",
          open: true,
          message: "Error in login. Please try again.",
        })
      );
    }
  };

  const onLoginError = (error) => {
    // console.log(error.message);
    dispatch(
      alertSlice.actions.setAlert({
        severity: "error",
        open: true,
        message: error?.response?.data?.message ?? error.message,
      })
    );
  };

  const onSubmit = (loginData, { setSubmitting }) => {
    delete loginData.remember;
    mutate(
      {
        ...loginData,
        password: window.btoa(loginData.password),
      },
      {
        onSuccess: onLoginSuccess,
        onError: onLoginError,
        onSettled: () => reset(),
      }
    );
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      remember: true,
    },
    validationSchema: loginFormValidationSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
        <Stack spacing={3} sx={{gap: "1rem"}}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Username"
            InputLabelProps={{shrink:true}}
            {...getFieldProps("username")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            InputLabelProps={{shrink:true}}
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Keep me logged in"
          />

          <Link variant="subtitle2" to="/reset" href="/reset">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting || isLoading}
        >
          Log in
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

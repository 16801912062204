// react
import { memo, useRef, useState } from "react";
import { useFormik } from "formik";
// mui components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper"
import { LoadingButton } from "@mui/lab";

// user defined components, services, constants
import Logo from "src/components/Logo";
import { authService } from "src/services";
import enums from "src/util/enums";
import VerificationAndReset from "./VerificationAndReset";
import { resetPasswordValidationSchmea } from "src/util/validationSchemas";

// hooks
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

//actions
import { alertSlice } from "src/store/alert/alert.slice";

function ResetPasswordForm() {
    const cardRef = useRef(null);
    const dispatch = useDispatch();
    const [resetState, setResetState] = useState({ in: false, user: '' });
    const { mutateAsync, isLoading } = useMutation(authService.resetPassword);
    
    const onSubmit = async (values, { setSubmitting }) => {
        await mutateAsync(values, {
            onSuccess: () => {
                setResetState({
                    in: !0,
                    user: values.username
                });
            },
            onError: (error) => {
                const { actions: { setAlert } } = alertSlice
                dispatch(setAlert({
                    open: true,
                    message: error?.response?.data?.message ?? error?.message,
                    severity: enums.SEVERITY.ERROR,
                }));
            },
        });
        setSubmitting(!1);
    }

    const formik = useFormik({
        initialValues: {
          username: "",
        },
        validationSchema: resetPasswordValidationSchmea,
        onSubmit,
    });
    const { errors, touched, handleSubmit, getFieldProps } = formik;

    return (
    <Container className="reset-password" id="reset-password">
        <Box sx={{ mt:7}} className="reset-password__main">
            <Logo />
            <Box sx={{ width:{xs: '95%',sm:'60%'}}} mx='auto' className="reset-password__form" ref={cardRef}>
                <Card variant="outlined">
                    {!resetState.in && 
                    <CardContent>
                        <Paper onSubmit={handleSubmit} component="form" elevation={0}>
                            <Typography variant="h4">Password Assistance</Typography>
                            <Typography variant="body2">Enter the username associated with the Oaza cms</Typography>
                            <FormControl fullWidth variant="standard">
                                <Typography variant="caption">Enter Username</Typography>
                                <TextField 
                                    size="small"
                                    disabled={isLoading}
                                    error={Boolean(touched.username && errors.username)}
                                    helperText={touched.username && errors.username}
                                    {...getFieldProps("username")}
                                />
                            </FormControl>
                            <LoadingButton type="submit" fullWidth variant="contained" loading={isLoading}>Continue</LoadingButton>
                        </Paper>
                    </CardContent>}
                    <VerificationAndReset 
                        in={resetState.in} 
                        container={cardRef.current} 
                        user={resetState.user}
                    />
                </Card>
            </Box>
        </Box>
    </Container>
    )
}

export default memo(ResetPasswordForm);
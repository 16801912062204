export const remoteOperations = [
  { label: "Change Availability", value: "ChangeAvailability" },
  { label: "Change Configuration", value: "ChangeConfiguration" },
  { label: "Remote Start", value: "RemoteStart" },
  { label: "Remote Stop", value: "RemoteStop" },
  { label: "Get Diagnostics", value: "GetDiagnostics" },
  { label: "Update Firmware", value: "UpdateFirmware" },
  { label: "Reset", value: "Reset" },
  { label: "Trigger Message", value: "TriggerMessage" },
  { label: "Unlock Connector", value: "UnlockConnector" },
];

import { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography, Box } from "@mui/material";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
// import AuthSocial from "../components/authentication/AuthSocial";
import Token from "src/util/Token";
import enums from "src/util/enums";
import Logo from "src/components/Logo";
import loginLogo from "src/image/logo/loginPageLogo.jpg"
import "./pages.css"
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [error, setError] = useState(null);
  useEffect(() => {
    const redirectedURL = new URLSearchParams(window.location.search);
    const loginStatus = redirectedURL.get("emailVerificationStatus");
    switch (loginStatus) {
      case "alreadyVerified":
        setError("Email is already verified, Please login.");
        break;
      case "false":
        setError("Email verification failed, Please try again or contact us.");
        break;
      default:
        //  Token.get() && (window.location.href = enums.path.DASHBOARD_APP);
        break;
    }
  }, []);

  return (
    <RootStyle title="Login | Smart-CMS">
      <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100vh", overflow:"hidden" }}>
        <Box className="container" sx={{ display: "grid", gridTemplateColumns: "60% 40%", placeItems: "center", height: "100vh" }}>
          <Box sx={{ position: "relative", width: "100%", height: { xs: "auto", md: "100%" }, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            src={loginLogo}
            alt="login"
            style={{height: "inherit"}}
          />
          <Box as="div" sx={{
              position: 'absolute',
              top: 0,
              left: 50,
              height: '100%',
              width: '46.5%',
              backgroundColor: 'rgba(0, 85, 164, 0.56)',
              color: 'white',
              padding: '0 15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontFamily: 'Poppins, sans-serif',
            }} className="transparent_container">
            <Typography variant="h2" className="logo_heading">SMART Charging Management System (SMART CMS)</Typography>
            <Typography variant="body1" className="logo_para">Smart Charging Management System provides you complete control over operating your EV Charging infrastructure, eases the management of your charging networks through one control panel, and offers comprehensive insights into EV drivers' charging activities. This enables you to analyze usage patterns and optimize operations to maximize profitability.</Typography>
          </Box>
          </Box>
          <Box className="form_container" sx={{width: "70%", backgroundColor:'white', padding: '40px 20px', borderRadius:'15px', minHeight: '85vh', display: 'flex', flexDirection: 'column', justifyContent:'space-evenly'}} >
          <Stack sx={{ mb: 4 }}>
              <Typography className="header_signin" sx={{ display: 'flex', alignItems: 'center', mb:2, fontSize: '30px', fontWeight:'bold' }}>
                Sign in - <Logo />
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize:"15px" }}>
                Login your account in seconds.
              </Typography>
            </Stack>
            <LoginForm />
            <Typography variant="body2" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/sign-up">
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get started
        </Link>
       </AuthLayout> */}
    </RootStyle>
  );
}

import axios from "axios";
import Token from "src/util/Token";
import { loginUrl, resetUrl, verificationAndResetUrl, changePasswordUrl } from "src/react-query/endPoints";

// Authentication Services
async function login(loginData) {
  return await axios.post(loginUrl, loginData);
}

async function resetPassword(resetReqBody) {
  return await axios.post(resetUrl, resetReqBody);
}

async function verificationAndReset(verifyReqBody) {
  return await axios.post(verificationAndResetUrl, verifyReqBody);
}

async function changePassword(changeReqBody) {
  return await axios.post(changePasswordUrl, changeReqBody, { headers: {
    'Authorization': `Bearer ${Token.get()}`
  }});
}

const authService = {
  login,
  resetPassword,
  verificationAndReset,
  changePassword
};
export default authService;

import { useMatch, useNavigate, useParams } from "react-router-dom";
import { Card, Grid, Stack, TextField } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { roleFormValidationSchema } from "src/util/validationSchemas";
import { getRoleUrlId, roleUrl } from "src/react-query/endPoints";
import { useList, useGetDetail, useMutate } from "src/hooks/useFetch.hook";
import { useEffect } from "react";
import { BaseTable } from "../BaseTable/BaseTable";
import { getRoleDefaultValue, featureColumns } from "./roles.constants";
import { LoadingButton } from "@mui/lab";
import { Link as RouterLink } from "react-router-dom";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import { is } from "src/util/is";
import { Loader } from "../Loader";
import { commonSlice } from "src/store/common/common.slice";
import { useDispatch, useSelector } from "react-redux";

export const RoleForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const featuresAccess = useSelector(({ common }) => common.features);
  const { mutate, isLoading } = useMutate("roles");
  const isCreateRole = useMatch("/roles/new");
  const { roleId } = useParams();
  const hasReadOnlyAccess = useAccess(FEATURE.ROLES, "R");
  const isReadOnly = hasReadOnlyAccess && !isCreateRole;
  const { data: role, isFetching } = useGetDetail("role", {
    id: roleId,
  });
  const { data: features, isFetching: featureLoading } = useList(
    "features",
    is.empty(roleId)
  );
  const onSubmit = async (roleData, { setSubmitting }) => {
    const mutateObject = {
      url: roleUrl,
      method: "POST",
    };

    if (!is.nullOrUndefined(roleId) && !isCreateRole) {
      mutateObject.method = "PUT";
      mutateObject.url = getRoleUrlId(roleId);
    }

    mutate(
      {
        url: mutateObject.url,
        method: mutateObject.method,
        body: { ...roleData, access: featuresAccess },
      },
      { onSuccess }
    );
    setSubmitting(false);
  };

  const onSuccess = () => navigate("/roles");

  const formik = useFormik({
    initialValues: getRoleDefaultValue(),
    validationSchema: roleFormValidationSchema,
    onSubmit,
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
  } = formik;

  useEffect(() => {
    if (role) {
      resetForm({ values: role });
      dispatch(commonSlice.actions.setFeatures(role?.access ?? []));
    }
  }, [role, resetForm, dispatch]);

  useEffect(() => {
    const access =
      features?.map((feature) => ({
        ...feature,
        create: "N",
        view: "N",
        update: "N",
        delete: "N",
      })) ?? [];
    is.empty(roleId) && dispatch(commonSlice.actions.setFeatures(access));
  }, [dispatch, features, roleId]);

  if (isFetching) {
    return <Loader />;
  }

  if (!isCreateRole && is.empty(role)) {
    navigate("/roles");
    return null;
  }

  return (
    <Card sx={{ p: 2 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" id="roles" noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} mb={2}>
              <TextField
                id="outlined-basic"
                label="Enter Role Name"
                variant="outlined"
                {...getFieldProps("roleName")}
                error={Boolean(touched.roleName && errors.roleName)}
                helperText={touched.roleName && errors.roleName}
              />
            </Grid>
          </Grid>
          <BaseTable
            rows={featuresAccess ?? []}
            columns={featureColumns}
            loading={featureLoading}
            getRowId={(row) => row.featureId}
            pagination={false}
            height={480}
          />
          <Grid container mt={3}>
            <Grid item xs={6} lg={6}>
              <Stack direction="row" spacing={2}>
                {!isReadOnly && (
                  <>
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={isReadOnly}
                      loading={isSubmitting || isLoading}
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      size="large"
                      type="button"
                      variant="outlined"
                      component={RouterLink}
                      to="/roles"
                    >
                      Cancel
                    </LoadingButton>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Card>
  );
};

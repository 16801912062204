import { Link as RouterLink } from "react-router-dom";
import { Link, Tooltip } from "@mui/material";

export const reportListColumn = (isReadOnly) => ([
    {
        field: "transaction_pk",
        headerName: "Transaction ID",
        flex: 0.5,
        renderCell: ({ value, id }) => (
            <>
            {isReadOnly?value:<Link underline="none" component={RouterLink} to={`/transactions/${id}`}>
              {value}
            </Link>}
            </>
          ),
    },
    {
        field: "charge_box_id",
        headerName: "Charger Box",
        flex: 0.5,
        renderCell: ({ value }) => (
            <Tooltip title={value} placement="top">
              <span>{value}</span>
            </Tooltip>
          ),
    },
    {
        field: "connector_id",
        headerName: "Connector ID",
        flex: 0.5,
    },
    {
        field: "start_value",
        headerName: "Start Value",
        flex: 0.5,
    },
    {
        field: "stop_value",
        headerName: "Stop Value",
        flex: 0.5,
    },
    {
        field: "totalEnergy",
        headerName: "Total Energy Consumed",
        flex: 0.7,
    },
    {
        field: "payment_type",
        headerName: "Transaction Method",
        flex: 0.5,
    },
    {
        field: "amount_paid",
        headerName: "Amount Paid",
        flex: 0.5,
    },
    {
        field: "charging_cost",
        headerName: "Transaction Cost",
        flex: 0.5,
    },
    {
        field: "cm_commission",
        headerName: "CM Commission",
        flex: 0.5,
    },
    {
        field: "payout_with_gst",
        headerName: "Payout With GST",
        flex: 0.5,
    },
    {
        field: "payout_without_gst",
        headerName: "Payout Without GST",
        flex: 0.5,
    },
    {
        field: "status",
        headerName: "Payout Status",
        flex: 0.5,
        renderCell: ({ value }) => (
            <Tooltip title={value} placement="top">
              <span>{value}</span>
            </Tooltip>
          ),
    }
]);

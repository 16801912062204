import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DataGrid, GridNoRowsOverlay } from "@mui/x-data-grid";
import ExportCSV from "../ExportCsv/ExportCSV";
import { is } from "src/util/is";

export const BaseTable = ({
  rows,
  columns,
  rowOrCellClickRedirect,
  pagination = true,
  pageSize = 5,
  height,
  sx,
  rowsPerPageOptions = [10, 20, 30],
  showExportCsvBtn,
  csvMorphemes,
  csvColumns,
  sortModel,
  onSortModelChange,
  isReadOnly,
  ...restProps
}) => {
  const [pagesize, setPageSize] = useState(pageSize);
  const [tableColumns] = useState(()=>!isReadOnly?columns:columns.filter(({field})=>field.toLowerCase()!=="actions"));
  //TO SPECIFICALLY NOT SHOW COLUMNS; STATUS AND NOTES FOR TRANSCATIONS TABLE
  // const [updatedColumnsForTransactionsTable] = useState(()=>csvMorphemes==="transactions"?tableColumns.filter(({field})=>field!=="status" && field!=="notes"):[])
  const rowClickHandler = (data, event) => {
    event.stopPropagation();
    const correspondingColumnName = data.field;
    if (correspondingColumnName) {
      if (correspondingColumnName.toLowerCase() === "actions") return;
      else {
        if (rowOrCellClickRedirect) rowOrCellClickRedirect(data);
        return;
      }
    }
  };

  return (
    <>
      {showExportCsvBtn && rows && rows.length > 0 && <ExportCSV rows={rows} columns={csvColumns} csvMorphemes={csvMorphemes} />}
      <Stack alignItems="flex-end" spacing={2}>
      <Box sx={{ height: "auto", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={tableColumns}
          {...(pagination && { pageSize: pagesize })}
          {...(pagination && { rowsPerPageOptions: rowsPerPageOptions })}
          {...(!pagination && { hideFooter: true })}
          {...(sortModel && { sortModel: sortModel })}
          {...(onSortModelChange && { onSortModelChange: onSortModelChange })}
          {...(sx && { sx: sx })}
          disableSelectionOnClick
          pagination={pagination}
          disableColumnResize={true}
          {...restProps}
          components={GridNoRowsOverlay}
          autoHeight={!0}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onCellClick={rowClickHandler}
        />
      </Box>
      </Stack>
    </>
  );
};

import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { fetchApi, fetchWithError } from "src/react-query/fetchWithError";
import { queryKeys } from "src/react-query/queryKeys";
import { selector } from "src/react-query/select";
import { alertSlice } from "src/store/alert/alert.slice";
import { is } from "src/util/is";

export const useChargingProfileList = () => {
  let keys = queryKeys.chargingProfileList();

  return useQuery(keys, fetchWithError, {
    select: selector,
  });
};

export const useChargingProfileDetails = (chargingProfileId, operation) => {
  let keys = queryKeys.chargingProfileDetails(chargingProfileId);
  return useQuery(keys, fetchWithError, {
    enabled: operation === "edit" && !is.empty(chargingProfileId),
    select: selector,
  });
};

export const useChargingProfileMutate = (queryKey, reset = true) => {
  const dispatch = useDispatch();
  const chargingProfileMutate = useMutation(fetchApi, {
    onSuccess: (data) => {
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: data?.message,
          severity: "success",
        })
      );
    },
    onError: (error) => {
      dispatch(
        alertSlice.actions.setAlert({
          open: true,
          message: error?.response?.data?.message ?? error?.message,
          severity: "error",
        })
      );
    },
    onSettled: () => {
      reset && chargingProfileMutate.reset();
    },
  });
  return chargingProfileMutate;
};

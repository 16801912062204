import { useQuery } from "@tanstack/react-query";
import { fetchWithError } from "src/react-query/fetchWithError";
import { queryKeys } from "src/react-query/queryKeys";
import { selector } from "src/react-query/select";
import { is } from "src/util/is";

export const useTransactions = ({ teamId }) => {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  let keys = queryKeys.transactions(teamId);

  if (params.has('charger'))
    keys = queryKeys.chargerTransactions(teamId, params.get('charger'));
  
  if (params.has('tag')) 
    keys = queryKeys.tagTransactions(teamId, params.get('tag'));

  return useQuery(keys, fetchWithError, {
    enabled: !is.empty(teamId),
    select: selector,
  });
};

export const useTransactionsId = ({ teamId, transactionId }) => {
  return useQuery(
    queryKeys.transactionsId(teamId, transactionId),
    fetchWithError,
    {
      enabled: !is.empty(teamId),
      select: selector,
    }
  );
};

import { MenuItem } from "@mui/material";

export const remoteOperationConfig = {
  ChangeAvailability: [
    {
      fieldName: "connectorId",
      required: true,
      type: "DROPDOWN",
      label: "Select connector id",
      validationType: "string",
      queryKey: "getConnectorsUrl",
      formatOptions: ({connector_id,status}) => (
        <MenuItem value={connector_id}>{`ConnectorId-${connector_id} (${status==="Available"?"Available to use":status})`}</MenuItem>
      ),
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
    {
      fieldName: "availabilityType",
      required: true,
      label: "Select Availability Type",
      type: "DROPDOWN",
      options: ["INOPERATIVE", "OPERATIVE"],
      validationType: "string",
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
  ],
  ChangeConfiguration: [
    {
      fieldName: "keyType",
      required: true,
      type: "DROPDOWN",
      label: "Select key type",
      options: ["Predefined", "Custom"],
      validationType: "string",
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
    {
      fieldName: "confKey",
      required: false,
      componentDependentOn: {
        field: "keyType",
        requiredCriteriaValue: "Predefined",
        disabledCriteriaValue: "Custom",
      },
      label: "Select Configuration key",
      type: "DROPDOWN",
      options: [
        {
          label: "HeartBeatInterval(in seconds)",
          value: "HeartBeatInterval",
        },
        {
          label: "ConnectionTimeOut(in seconds)",
          value: "ConnectionTimeOut",
        },
        {
          label: "ProximityContactRetries(in times)",
          value: "ProximityContactRetries",
        },
        {
          label: "ProximityLockRetries(in times)",
          value: "ProximityLockRetries",
        },
        {
          label: "ResetRetries(in times)",
          value: "ResetRetries",
        },
        {
          label: "BlinkRepeat(in times)",
          value: "BlinkRepeat",
        },
        {
          label: "LightIntensity(in %)",
          value: "LightIntensity",
        },
        {
          label: "ChargePointId(string)",
          value: "ChargePointId",
        },
        {
          label: "MeterValueSampleInterval(in seconds)",
          value: "MeterValueSampleInterval",
        },
        {
          label: "ClockAlignedDataInterval(in seconds)",
          value: "ClockAlignedDataInterval",
        },
        {
          label: "MeterValuesSampledData(comma separated list)",
          value: "MeterValuesSampledData",
        },
        {
          label: "MeterValuesAlignedData(comma separated list)",
          value: "MeterValuesAlignedData",
        },
        {
          label: "StopTxnSampledData(comma separated list)",
          value: "StopTxnSampledData",
        },
        {
          label: "StopTxnAlignedData(comma separated list)",
          value: "StopTxnAlignedData",
        },
        {
          label: "AllowOfflineTxForUnknownId(boolean)",
          value: "AllowOfflineTxForUnknownId",
        },
        {
          label: "AuthorizationCacheEnabled(boolean)",
          value: "AuthorizationCacheEnabled",
        },
        {
          label: "AuthorizeRemoteTxRequests(boolean)",
          value: "AuthorizeRemoteTxRequests",
        },
        {
          label: "ConnectorPhaseRotation(comma separated list)",
          value: "ConnectorPhaseRotation",
        },
        {
          label: "LocalAuthorizeOffline(boolean)",
          value: "LocalAuthorizeOffline",
        },
        {
          label: "LocalPreAuthorize(boolean)",
          value: "LocalPreAuthorize",
        },
        {
          label: "MaxEnergyOnInvalidId(in Wh)",
          value: "MaxEnergyOnInvalidId",
        },
        {
          label: "MinimumStatusDuration(in seconds)",
          value: "MinimumStatusDuration",
        },
        {
          label: "StopTransactionOnEVSideDisconnect(boolean)",
          value: "StopTransactionOnEVSideDisconnect",
        },
        {
          label: "StopTransactionOnInvalidId(boolean)",
          value: "StopTransactionOnInvalidId",
        },
        {
          label: "TransactionMessageAttempts(in times)",
          value: "TransactionMessageAttempts",
        },
        {
          label: "TransactionMessageRetryInterval(in seconds)",
          value: "TransactionMessageRetryInterval",
        },
        {
          label: "UnlockConnectorOnEVSideDisconnect(boolean)",
          value: "UnlockConnectorOnEVSideDisconnect",
        },
        {
          label: "WebSocketPingInterval(in seconds)",
          value: "WebSocketPingInterval",
        },
        {
          label: "LocalAuthListEnabled(boolean)",
          value: "LocalAuthListEnabled",
        },
        {
          label: "StopTransactionSignatureFormat(string; specific to OCMF)",
          value: "StopTransactionSignatureFormat",
        },
        {
          label:
            "StopTransactionSignatureContexts(comma separated list; specific to OCMF)",
          value: "StopTransactionSignatureContexts",
        },
        {
          label:
            "MeterValuesSignatureContexts(comma separated list; specific to OCMF)",
          value: "MeterValuesSignatureContexts",
        },
      ],
      validationType: "string",
      value: "",
      validations: [{ type: "optional", params: ["This field is required"] }],
    },
    {
      fieldName: "customConfKey",
      required: false,
      componentDependentOn: {
        field: "keyType",
        requiredCriteriaValue: "Custom",
        disabledCriteriaValue: "Predefined",
      },
      type: "TEXT",
      label: "Enter custom configuration key",
      validationType: "string",
      value: "",
      validations: [{ type: "optional", params: ["This field is required"] }],
    },
    {
      fieldName: "customConfValue",
      required: true,
      type: "TEXT",
      label: "Enter value",
      validationType: "string",
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
  ],
  RemoteStart: [
    {
      fieldName: "connectorId",
      required: true,
      type: "DROPDOWN",
      label: "Select connector id",
      validationType: "string",
      queryKey: "getConnectorsUrl",
      formatOptions: ({connector_id,status}) => (
        <MenuItem value={connector_id}>{`ConnectorId-${connector_id} (${status==="Available"?"Available to use":status})`}</MenuItem>
         
      ),
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
    {
      fieldName: "idTag",
      required: true,
      label: "Select OCCP Tag",
      type: "DROPDOWN",
      validationType: "string",
      queryKey: "tagsForRemoteOp",
      useEmail:true,
      formatOptions: ({ tagId }) => <MenuItem value={tagId}>{tagId}</MenuItem>,
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
  ],
  RemoteStop: [
    {
      fieldName: "transactionId",
      required: true,
      type: "DROPDOWN",
      usePk: true,
      label: "Select Transaction Id",
      validationType: "string",
      queryKey: "chargerActiveTransactions",
      formatOptions: ({ transactionId, connectorId }) => (
        <MenuItem value={transactionId}>
          {`TransactionId - ${transactionId} | ConnectorId - ${connectorId}`}
        </MenuItem>
      ),
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
  ],
  GetDiagnostics: [
    {
      fieldName: "location",
      required: true,
      type: "TEXT",
      label: "Enter Location (directory URI)",
      validationType: "string",
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
    {
      fieldName: "retries",
      required: false,
      label: "Retries",
      type: "TEXT",
      validationType: "string",
      value: "",
      validations: [{ type: "optional", params: [] }],
    },
    {
      fieldName: "retryInterval",
      required: false,
      label: "Retry Interval",
      type: "TEXT",
      validationType: "string",
      value: "",
      validations: [{ type: "optional", params: [] }],
    },
    {
      fieldName: "start",
      required: true,
      label: "Start Date/Time",
      type: "DATE/TIME",
      validationType: "date",
      value: null,
      validations: [{ type: "optional", params: ["This field is required"] }],
    },
    {
      fieldName: "stop",
      required: true,
      label: "Stop Date/Time",
      type: "DATE/TIME",
      validationType: "date",
      value: null,
      validations: [{ type: "optional", params: ["This field is required"] }],
    },
  ],
  Reset: [
    {
      fieldName: "resetType",
      required: true,
      label: "Select Reset Type",
      type: "DROPDOWN",
      options: ["HARD", "SOFT"],
      validationType: "string",
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
  ],
  UpdateFirmware: [
    {
      fieldName: "location",
      required: true,
      type: "TEXT",
      label: "Enter Location (directory URI)",
      validationType: "string",
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
    {
      fieldName: "retries",
      required: false,
      label: "Retries",
      type: "TEXT",
      validationType: "string",
      value: "",
      validations: [{ type: "optional", params: [] }],
    },
    {
      fieldName: "retryInterval",
      required: false,
      label: "Retry Interval",
      type: "TEXT",
      validationType: "string",
      value: "",
      validations: [{ type: "optional", params: [] }],
    },
    {
      fieldName: "retrieveDate",
      required: true,
      label: "Retrieve Date/Time",
      type: "DATE/TIME",
      validationType: "date",
      value: null,
      validations: [{ type: "required", params: ["This field is required"] }],
    },
    {
      fieldName: "stop",
      required: true,
      label: "Stop Date/Time",
      type: "DATE/TIME",
      validationType: "date",
      value: null,
      validations: [{ type: "optional", params: ["This field is required"] }],
    },
  ],
  TriggerMessage: [
    {
      fieldName: "triggerMessage",
      required: true,
      label: "Select Trigger Message",
      type: "DROPDOWN",
      options: [
        "BootNotification",
        "DiagnosticsStatusNotification",
        "FirmwareStatusNotification",
        "Heartbeat",
        "MeterValues",
        "StatusNotification",
      ],
      validationType: "string",
      value: "BootNotification",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
    {
      fieldName: "connectorId",
      required: true,
      type: "TEXT",
      label: "Enter connector id",
      validationType: "string",
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
  ],
  UnlockConnector: [
    {
      fieldName: "connectorId",
      required: true,
      type: "TEXT",
      label: "Enter connector id",
      validationType: "string",
      value: "",
      validations: [{ type: "required", params: ["This field is required"] }],
    },
  ],
};
